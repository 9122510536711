import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import 'dayjs/locale/en-gb';
import { useState } from 'react';
import ApiRequest from 'services/api.service';
import AddUpdateMemberDialog from './AddUpdateMemberDialog';
import MemberView from './MemberView';

const MemberDetailsForm = ({
  configData,
  registration,
  setRegistration,
  nextStep,
  prevStep,
  defaultExpanded = false,
  viewOnly = false
}) => {

  const [dialogData, setDialogData] = useState({
    open: false,
    member: null,
  });

  const setMembers = (members) => {
    const updatedRegistration = {
      ...registration,
      members: members, // Use computed property name syntax to set the key dynamically
    };
    setRegistration(updatedRegistration);
  }

  const handleOpenDialog = (member = null) => {
    setDialogData({
      open: true,
      member,
    });
  };

  const handleCloseDialog = () => {
    setDialogData({
      open: false,
      member: null,
    });
  };

  const handlePrev = (event) => {
    event.preventDefault();
    prevStep();
  };

  const handleSubmit = () => {
    if (registration.members.length === 0) {
      alert('Please add at least one member before proceeding.');
    } else {
      const adultPresent = registration.members.some(member => member.age > configData.ageLimit)
      if(adultPresent) {
        nextStep();
      } else {
        alert(`At least one member must be older than ${configData.ageLimit} years.`);
      }
    }
  };

  const handleDelete = async (member) => {
    const confirmed = window.confirm(`Are you sure you want to delete ${member.name}?`);
    if (!confirmed) return;

    try {
      const updatedMembers = await ApiRequest.handleDeleteApiRequest(
        '/member',
        { memberId: member.id, regId: registration.id }
      );

      if (!updatedMembers) {
        alert('Failed to delete the member. Please try again later.');
        return;
      }

      setMembers(updatedMembers);
      alert(`${member.name} has been successfully deleted.`);
    } catch (error) {
      console.error('Error while deleting the member:', error);
      alert('An error occurred while deleting the member. Please try again.');
    }
  };

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [expanded, setExpanded] = useState(defaultExpanded || !viewOnly)

  return (
    <>
      <div className="page-section">
        <Box>
          <Accordion key='members' sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary key={'member_summary'} expandIcon={<ExpandMoreIcon />}>
              <Typography variant="title"><strong>Members</strong></Typography>
            </AccordionSummary>
            <AccordionDetails key='member_details'>
            {registration.members.map((member, memberIndex) => (
                <MemberView
                    member={member}
                    memberIndex={memberIndex}
                    handleEditMember={handleOpenDialog}
                    handleDeleteMember={handleDelete}
                    defaultExpanded={defaultExpanded}
                    setMembers={setMembers}
                    viewOnly={viewOnly}
                />
            ))}
              {!viewOnly && (
                <Grid item xs={2} sm={1} ml={3}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="left"
                    onClick={() => handleOpenDialog()}
                    style={{ cursor: 'pointer' }}
                  >
                    <AddCircleIcon color="primary" />
                    <Typography variant="body2" color="primary">
                      Add member
                    </Typography>
                  </Box>
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
      {!viewOnly && (
        <>
          <Divider />
          <div className="registration-form-content-actions">
            <Button
              variant="contained"
              onClick={handlePrev}
              sx={{ mt: 1 }}
              startIcon={<KeyboardArrowLeftIcon className="xyz-white-icon" />}
            >
              {isSmallScreen ? <span style={{ fontSize: '8px' }}>Back</span> : 'Back'}
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ mt: 1 }}
              endIcon={<KeyboardArrowRightIcon className="xyz-white-icon" />}
            >
              {isSmallScreen ? <span style={{ fontSize: '8px' }}>Next</span> : 'Next'}
            </Button>
          </div>
        </>
      )}
      <AddUpdateMemberDialog
        openDialog={dialogData.open}
        member={dialogData.member}
        handleCloseDialog={handleCloseDialog}
        configData={configData}
        setMembers={setMembers}
        regId={registration.id}
        userDetails={registration.userDetails}
      />
    </>
  );
};

export default MemberDetailsForm;
