import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField,
    Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { Form, Formik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useState } from 'react';
import ApiRequest from 'services/api.service';
import * as Yup from 'yup';
import { countryCodeData } from '../../constants/jsons/country';

const AddUpdateMemberDialog = ({
    openDialog,
    handleCloseDialog,
    configData,
    regId,
    member,
    setMembers,
    userDetails,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const ageLimit = configData.ageLimit || 5;
    const transportationPreferences = configData.transportationPreferences.reduce(
        (acc, option) => {
            acc[option.type] = option;
            return acc;
        }, {});

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        initiatedName: Yup.string(),
        age: Yup.number()
            .required('Age is required')
            .min(0, 'No time travelers allowed! Age: 0+.')
            .max(150, 'Stay under 150! No age-limit exceeded!')
            .integer('Stay whole: Age: Integer vibes only!'),
        gender: Yup.string().required('Gender is required'),
        city: Yup.string().required('City is required'),
        countryCode: Yup.string().required('Country code is required')
            .test('validCountryCode', 'Country code is not valid', function (value) {
                return value && countryCodeData.includes(value);
            }),
        contactNumber: Yup.string()
            .required('Contact number is required')
            .test('validPhoneNumber', 'Phone number is Invalid for the country', function (value) {
                const countryCode = this.parent.countryCode;
                try {
                    return !countryCode || !value || parsePhoneNumber(countryCode + value).isValid();
                } catch (error) {
                    console.log(error);
                    return false;
                }
            }),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        preferredHearingLanguage: Yup.string().required('Preferred hearing language is required'),
        arrivalTime: Yup.date()
            .required('Arrival date is required')
            .typeError('Arrival date must be a valid date')
            .test('is-in-range', 'Selected date is out of range', function (value) {
                const startDate = dayjs(configData.startDate).startOf('day');
                const endDate = dayjs(configData.endDate).startOf('day');
                const selectedDate = dayjs(value).startOf('day');
                return !value || (selectedDate.diff(startDate, 'day') >= 0 && selectedDate.diff(endDate, 'day') <= 0);
            }),
        departureTime: Yup.date()
            .required('Departure date is required')
            .typeError('Departure date must be a valid date')
            .test('is-in-range', 'Selected date is out of range', function (value) {
                const startDate = dayjs(configData.startDate).startOf('day');
                const endDate = dayjs(configData.endDate).startOf('day');
                const selectedDate = dayjs(value).startOf('day');
                return !value || (selectedDate.diff(startDate, 'day') >= 0 && selectedDate.diff(endDate, 'day') <= 0);
            })
            .min(Yup.ref('arrivalTime'), 'Departure date must be later than or equal to arrival date'),
        transportationPreference: Yup.string().required('Transportation estimate is required'),
        prasadamEstimate: Yup.number().required('Prasadam estimate is required').min(0, 'Prasadam estimate must be a non-negative number'),
        registrationCharges: Yup.number().required('Registration charges are required').min(0, 'Registration charges must be a non-negative number'),
        yatraCharges: Yup.number().required('Yatra charges are required').min(0, 'Yatra charges must be a non-negative number'),
        lateFees: Yup.number().min(0, 'Late fees must be a non-negative number'),
    });


    const initialValues = {
        id: member?.id || '',
        index: member?.index || '',
        name: member?.name || '',
        initiatedName: member?.initiatedName || '',
        age: member?.age || '',
        gender: member?.gender || '',
        city: member?.city || userDetails.city || '',
        countryCode: member?.countryCode || userDetails.countryCode || '',
        contactNumber: member?.contactNumber || userDetails.contactNumber || '',
        email: member?.email || userDetails.email || '',
        preferredHearingLanguage: member?.preferredHearingLanguage || configData.preferredHearingLanguage[0] || '',
        arrivalTime: member?.arrivalTime || '',
        departureTime: member?.departureTime || '',
        transportationPreference: member?.transportationPreference || '',
        prasadamEstimate: member?.prasadamEstimate || 0,
        registrationCharges: member?.registrationCharges || 0,
        yatraCharges: member?.yatraCharges || 0,
        lateFees: member?.lateFees || 0
    };

    const handleSubmit = async (values) => {
        setIsSubmitting(true);

        const memberDTO = {
            id: values.id,
            index: '',
            name: values.name,
            initiatedName: values.initiatedName,
            age: values.age,
            gender: values.gender,
            city: values.city,
            countryCode: values.countryCode,
            contactNumber: values.contactNumber,
            email: values.email,
            preferredHearingLanguage: values.preferredHearingLanguage,
            arrivalTime: values.arrivalTime,
            departureTime: values.departureTime,
            transportationPreference: values.transportationPreference,
            prasadamEstimate: values.prasadamEstimate,
            registrationCharges: values.registrationCharges,
            yatraCharges: values.yatraCharges,
            lateFees: values.lateFees,
        };

        const response = await ApiRequest.handlePostApiRequest(
            '/member',
            memberDTO,
            { regId: regId }
        );
        handleCloseDialog();
        setIsSubmitting(false);

        if (!response) {
            alert('Error while saving the data');
            return;
        }
        setMembers(response);
        // setIsSubmitting(false);
        // handleCloseDialog();
    };

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleCloseDialog();
    };

    const handleDateChange = (fieldName, newValue, setFieldValue) => {
        // Format the date in local time zone instead of converting it to UTC
        const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : '';

        setFieldValue(fieldName, formattedDate);
    };

    const handleAgeChange = (event, setFieldValue) => {
        const age = parseInt(event.target.value, 10);

        // Check if the age is a valid number and not NaN
        if (!isNaN(age)) {
            setFieldValue('age', age); // Update Formik's field value

            if (age > ageLimit) {
                const lateFees = dayjs().isAfter(dayjs(configData.lateFeesDate))
                    ? configData.lateFeesCharge
                    : 0;
                setFieldValue('prasadamEstimate', configData.prasadamEstimate);
                setFieldValue('registrationCharges', configData.registrationCharges);
                setFieldValue('yatraCharges', configData.yatraCharges);
                setFieldValue('lateFees', lateFees);
            } else {
                setFieldValue('prasadamEstimate', 0);
                setFieldValue('registrationCharges', 0);
                setFieldValue('yatraCharges', 0);
                setFieldValue('lateFees', 0);
            }
        } else {
            setFieldValue('age', ''); // Reset the field if input is invalid
            // Optionally, reset the other values as well
            setFieldValue('prasadamEstimate', 0);
            setFieldValue('registrationCharges', 0);
            setFieldValue('yatraCharges', 0);
            setFieldValue('lateFees', 0);
        }
    }

    const getDestinationInfo = (values, destination) => {
        if (destination.checkOut > configData.endDate) {
            return;
        }
        const arrivalTime = dayjs(values.arrivalTime).startOf('day');
        const departureTime = dayjs(values.departureTime).startOf('day');
        const checkIn = dayjs(destination.checkIn).startOf('day');
        const checkOut = dayjs(destination.checkOut).startOf('day');

        const singleDay = arrivalTime.isSame(departureTime) && (arrivalTime.isSame(checkIn) || arrivalTime.isSame(checkOut));

        const isFullYatra = (arrivalTime.isSame(checkIn) || arrivalTime.isBefore(checkIn)) &&
            (departureTime.isSame(checkOut) || departureTime.isAfter(checkOut));

        const isPartYatra = (arrivalTime.isAfter(checkIn) && arrivalTime.isBefore(checkOut)) ||
            (departureTime.isBefore(checkOut) && departureTime.isAfter(checkIn));

        let conditionText;
        if (singleDay) {
            conditionText = 'This day may involve settling up or traveling';
        } else if (isFullYatra) {
            conditionText = 'You are attending the full yatra';
        } else if (isPartYatra) {
            conditionText = 'You are attending part of the yatra';
        } else {
            conditionText = 'You are not attending the yatra';
        }
        let chargeInfo = '';
        if (isPartYatra) {
            chargeInfo = `You will be charged for all the days in ${destination.displayText} room`;
        }

        // Display messages based on the comparison
        return (
            <>
                {conditionText} at <strong>{destination.displayText.toUpperCase()}. </strong>
                We are in {destination.location} from <strong>{dayjs(destination.checkIn).format('DD/MM/YYYY')} to {dayjs(destination.checkOut).format('DD/MM/YYYY')} </strong>.
                {chargeInfo}
                <br />
            </>
        );
    }

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>Add/Update the Member</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { resetForm }) => {
                        await handleSubmit(values);
                        resetForm();
                    }}
                >
                    {({ errors, touched, values, setFieldValue, getFieldProps, handleChange }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <TextField
                                        id="name"
                                        label="Name *"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('name')}
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    {/* Initiated Name */}
                                    <TextField
                                        id="initiatedName"
                                        label="Initiated Name"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('initiatedName')}
                                    />
                                </Grid>

                                <Grid item xs={12} >
                                    {/* Age */}
                                    <TextField
                                        id="age"
                                        label="Age *"
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('age')} // Use Formik's default handling
                                        onChange={(event) => { handleAgeChange(event, setFieldValue) }}
                                        error={touched.age && Boolean(errors.age)}
                                        helperText={
                                            <>
                                                {touched.age && errors.age ? (
                                                    errors.age
                                                ) : (
                                                    values.age <= 5 && (
                                                        <Typography style={{ color: 'green', fontSize: '0.8rem' }}>
                                                            For members aged {ageLimit} years old or younger, Prasadam, Registration, Yatra, and Late fees will be provided free of charge.
                                                        </Typography>
                                                    )
                                                )}
                                            </>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    {/* Gender */}
                                    <FormControl fullWidth variant="outlined" error={touched.gender && Boolean(errors.gender)}>
                                        <InputLabel id="gender-label">Gender *</InputLabel>
                                        <Select
                                            labelId="gender-label"
                                            id="gender"
                                            label="Gender *"
                                            {...getFieldProps('gender')}
                                        >
                                            <MenuItem value="">Select Gender</MenuItem>
                                            <MenuItem value="MALE">Male</MenuItem>
                                            <MenuItem value="FEMALE">Female</MenuItem>
                                        </Select>
                                        <FormHelperText>{touched.gender && errors.gender}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} >
                                    {/* Country Code */}
                                    <Autocomplete
                                        options={countryCodeData}
                                        getOptionLabel={(option) => option}
                                        fullWidth
                                        value={values.countryCode || ''}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Country Code *"
                                                variant="outlined"
                                                {...getFieldProps('countryCode')}
                                                error={touched.countryCode && Boolean(errors.countryCode)}
                                                helperText={touched.countryCode && errors.countryCode}
                                            />
                                        )}
                                        onChange={(event, value) => setFieldValue('countryCode', value || '')}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    {/* Contact Number */}
                                    <TextField
                                        id="contactNumber"
                                        label="Contact Number *"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('contactNumber')}
                                        error={touched.contactNumber && Boolean(errors.contactNumber)}
                                        helperText={touched.contactNumber && errors.contactNumber}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    {/* Email */}
                                    <TextField
                                        id="email"
                                        label="Email *"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('email')}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    {/* City */}
                                    <TextField
                                        id="city"
                                        label="City *"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('city')}
                                        error={touched.city && Boolean(errors.city)}
                                        helperText={touched.city && errors.city}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    {/* Preferred Hearing Language */}
                                    <FormControl fullWidth variant="outlined" error={touched.preferredHearingLanguage && Boolean(errors.preferredHearingLanguage)}>
                                        <InputLabel id="preferredHearingLanguage-label">Preferred Hearing Language *</InputLabel>
                                        <Select
                                            labelId="preferredHearingLanguage-label"
                                            id="preferredHearingLanguage"
                                            label="Preferred Hearing Language *"
                                            {...getFieldProps('preferredHearingLanguage')}
                                        >
                                            <MenuItem value="">Choose your preferred hearing Language</MenuItem>
                                            {configData.preferredHearingLanguage.map((code) => (
                                                <MenuItem key={code} value={code}>{code}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{touched.preferredHearingLanguage && errors.preferredHearingLanguage}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                    {/* Arrival Date */}
                                    <Grid item xs={12} >
                                        <FormControl fullWidth variant="outlined" error={touched.arrivalTime && Boolean(errors.arrivalTime)}>
                                            <DatePicker
                                                label="Arrival Date *"
                                                fullWidth
                                                sx={{ width: '100%' }}
                                                {...getFieldProps('arrivalTime')}
                                                value={values.arrivalTime ? dayjs(values.arrivalTime, 'YYYY-MM-DD') : null}
                                                onChange={(newValue) => handleDateChange('arrivalTime', newValue, setFieldValue)}
                                                minDate={dayjs(configData.startDate)}
                                                maxDate={values.departureTime ? dayjs(values.departureTime) : dayjs(configData.endDate)}
                                                error={touched.arrivalTime && Boolean(errors.arrivalTime)}
                                                helperText={touched.arrivalTime && errors.arrivalTime}
                                            />
                                            <FormHelperText>
                                                {touched.arrivalTime && errors.arrivalTime}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {/* Departure Date */}
                                    <Grid item xs={12} >
                                        <FormControl fullWidth variant="outlined" error={touched.departureTime && Boolean(errors.departureTime)}>
                                            <DatePicker
                                                label="Departure Date *"
                                                fullWidth
                                                sx={{ width: '100%' }}
                                                {...getFieldProps('departureTime')}
                                                value={values.departureTime ? dayjs(values.departureTime, 'YYYY-MM-DD') : null}
                                                onChange={(newValue) => handleDateChange('departureTime', newValue, setFieldValue)}
                                                minDate={values.arrivalTime ? dayjs(values.arrivalTime) : dayjs(configData.startDate)}
                                                maxDate={dayjs(configData.endDate)}
                                                error={touched.departureTime && Boolean(errors.departureTime)}
                                                helperText={touched.departureTime && errors.departureTime}
                                            />
                                            <FormHelperText>
                                                {touched.departureTime && errors.departureTime}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {values.arrivalTime && values.departureTime &&
                                        <FormHelperText style={{ fontSize: '0.8rem', color: 'green', paddingLeft: '1rem' }}>
                                            {configData.destinations.map((destination, index) => {
                                                return getDestinationInfo(values, destination)
                                            })}
                                        </FormHelperText>
                                    }
                                </LocalizationProvider>
                                <Grid item xs={12} >
                                    {/* Transportation Estimate */}
                                    <FormControl fullWidth variant="outlined" error={touched.transportationPreference && Boolean(errors.transportationPreference)}>
                                        <InputLabel id="transportationPreference-label">Transportation preference *</InputLabel>
                                        <Select
                                            labelId="transportationPreference-label"
                                            id="transportationPreference"
                                            label="Transportation Estimate *"
                                            {...getFieldProps('transportationPreference')}
                                            onChange={(event) => {
                                                handleChange(event)
                                                const selectedValue = event.target.value
                                                if (selectedValue === "") {
                                                    setFieldValue('transportationPreference', "");
                                                } else {
                                                    const selectedOption = transportationPreferences[event.target.value];
                                                    setFieldValue('transportationEstimate', selectedOption.type);
                                                }
                                            }}
                                        >
                                            <MenuItem value="">Choose your transportation preference</MenuItem>
                                            {Object.entries(transportationPreferences).map(([type, transportOption], index) => (
                                                <MenuItem key={index} value={type}>{transportOption.displayText} - ₹{transportOption.amount}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            {touched.transportationPreference && errors.transportationPreference ?
                                                (errors.transportationPreference) :
                                                (
                                                    <Typography style={{ color: 'green', fontSize: '0.8rem' }}>
                                                        {transportationPreferences[values.transportationPreference]?.info}
                                                    </Typography>
                                                )
                                            }
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} >
                                    {/* Prasadam Estimate */}
                                    <TextField
                                        id="prasadamEstimate-label"
                                        label="Prasadam per day *"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('prasadamEstimate')}
                                        error={touched.prasadamEstimate && Boolean(errors.prasadamEstimate)}
                                        helperText={touched.prasadamEstimate && errors.prasadamEstimate}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12} >
                                    {/* Yatra Charges */}
                                    <TextField
                                        id="yatraCharges-label"
                                        label="Yatra charges per day *"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('yatraCharges')}
                                        error={touched.yatraCharges && Boolean(errors.yatraCharges)}
                                        helperText={touched.yatraCharges && errors.yatraCharges}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    {/* Registration Charges */}
                                    <TextField
                                        id="registrationCharges-label"
                                        label="Registration Charges *"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('registrationCharges')}
                                        error={touched.registrationCharges && Boolean(errors.registrationCharges)}
                                        helperText={touched.registrationCharges && errors.registrationCharges}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    {/* Late Fees */}
                                    <TextField
                                        id="lateFees-label"
                                        label="Late Fees *"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('lateFees')}
                                        error={touched.lateFees && Boolean(errors.lateFees)}
                                        helperText={touched.lateFees && errors.lateFees}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>

                            <DialogActions>
                                <div
                                    className="registration-form-content-actions"
                                    style={{ justifyContent: 'space-around' }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={handleCloseDialog}
                                        disabled={isSubmitting}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default AddUpdateMemberDialog;
