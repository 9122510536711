export const sponsorshipItems = [
    {
        type: "BREAKFAST_PRASADAM",
        title: "Breakfast Prasadam",
        cost: "Rs. 200/- per person per day",
        total: "Rs. 1,20,000/- ($1435) per day for 600 devotees",
        amount: 120000,
        counts: 1
    },
    {
        type: "LUNCH_PRASADAM",
        title: "Lunch Prasadam",
        cost: "Rs. 300/- per person per day",
        total: "Rs. 1,80,000/- ($2150) per day for 600 devotees",
        amount: 180000,
        counts: 1
    },
    {
        type: "DINNER_PRASADAM",
        title: "Dinner Prasadam",
        cost: "Rs. 200/- per person per day",
        total: "Rs. 1,20,000/- ($1435) per day for 600 devotees",
        amount: 120000,
        counts: 1
    },
    {
        type: "CAR",
        title: "Maharaja's Car expenses",
        cost: "Rs 51,000/- ($601) entire yatra",
        total: "Rs 51,000/- ($601)",
        amount: 51000,
        counts: 1
    },
    {
        type: "HH_RPS_MAHARAJA_ACCOMODATION",
        title: "HH Romapada Maharaja's Accommodation expenses",
        cost: "Rs. 85,000/- ($1001) for entire yatra",
        total: "Rs. 85,000/- ($1001)",
        amount: 85000,
        counts: 1
    },
    {
        type: "COOKING",
        title: "Both Maharaja's Cooking expenses",
        cost: "Rs. 10,000/- ($120) per day ",
        total: "Rs. 1,00,000/- ($1201)",
        amount: 100000,
        counts: 10
    },
    {
        type: "GARLAND",
        title: "Garland expenses for both Guru Maharaja & deities",
        cost: "Rs 40,000/- ($501) for entire yatra",
        total: "Rs 40,000/- ($501)",
        amount: 40000,
        counts: 1
    },
    {
        type: "MISC",
        title: "Miscellaneous Expenditures",
        cost: "Rs. 50,000/- ($600)",
        total: "Rs. 50,000/- ($600) for entire yatra",
        amount: 50000,
        counts: 1
    },
    {
        type: "INITIATION",
        title: "Initiation Expenditure",
        cost: "Rs. 25,000/- ($301)",
        total: "Rs. 25,000/- ($301)",
        amount: 25000,
        counts: 1
    },
    {
        type: "SOUND_SYSTEM",
        title: "Sound System",
        cost: "Rs. 8000/- ($101) per day",
        total: "Rs. 80,000/- ($1001) for 10 days",
        amount: 80000,
        counts: 10
    },
    {
        type: "DECORATION",
        title: "Banquet Hall Decoration Charges",
        cost: "Rs. 5000/- ($60) per day",
        total: "Rs. 50,000/- ($601) for 10 days",
        amount: 50000,
        counts: 10
    },
    {
        type: "SPONSOR_A_DEVOTEE",
        title: "Sponsor a devotee",
        cost: "Rs. 21,000/- ($251) per devotee",
        total: "Rs. 3,15,000/- ($3766) for 15 devotees",
        amount: 315000,
        counts: 15
    },
    {
        type: "EVIAN_WATER",
        title: "Evian water for Maharaja drinking & cooking",
        cost: "Rs. 3,000/- ($35) per day",
        total: "Rs. 45,000/- ($540) for 15 days",
        amount: 45000,
        counts: 15
    },
    {
        type: "BISLERI",
        title: "Bisleri Drinking Water for devotees during class & prasadam time",
        cost: "Rs. 5,000/- ($60) per day",
        total: "Rs. 50,000/- ($601) for 10 days",
        amount: 50000,
        counts: 10
    },
    {
        type: "BG",
        title: "Sponsor a Bhagavad Gita to be kept in hotel Rooms",
        cost: "Rs. 350 ($5) per book",
        total: "Rs. 1,75,000/- ($2101) for 500 Bhagavad Gita",
        amount: 175000,
        counts: 500
    }
];