import { Box, Button, Paper, Typography, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { ValidationPopup } from 'components/generics/ValidationPopup';
import { useState } from 'react';
import ApiRequest from 'services/api.service';
import LoginService from 'services/login.service';


const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const AdminActions = () => {

    const [error, setError] = useState('');
    const [confirmMessage, setConfirmMessage] = useState('')
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupAction, setPopupAction] = useState('error');
    const [popupType, setPopupType] = useState('error');

    const [paymentHistoryDTOs, setPaymentHistoryDTOs] = useState([])

    const downloadMembers = () => {
        ApiRequest.handleDownloadRequest('/admin/download/members/').then(() => {
            setIsPopupOpen(true)
            setConfirmMessage("Downloading the file please wait")
        }).catch(error => {
            console.error("Download failed:", error);
        }).finally(() => {
            setIsPopupOpen(false)
        });
    }

    const downloadRooms = () => {
        ApiRequest.handleDownloadRequest('/admin/download/rooms/').then(() => {
            setIsPopupOpen(true)
            setConfirmMessage("Downloading the file please wait")
        }).catch(error => {
            console.error("Download failed:", error);
        }).finally(() => {
            setIsPopupOpen(false)
        });
    };

    const addDriverPrasadamCharges = () => {
        setIsPopupOpen(true)
        setConfirmMessage("Adding the prasadam charges for driver please wait.")
        ApiRequest.handlePostApiRequest('/admin/driver/prasadam').then(() => {
            setConfirmMessage("Done with the adding prasadam charges for driver")
        }).catch(error => {
            console.error("Process failed:", error);
        }).finally(() => {
            setIsPopupOpen(false)
        });
    }

    const updateAllPaymentStatus = () => {
        setIsPopupOpen(true)
        setConfirmMessage("Updating the payment status for all the devotees please wait.")
        ApiRequest.handlePostApiRequest('/admin/payment/status').then(() => {
            setConfirmMessage("Done with the update of payment status")
        }).catch(error => {
            console.error("Process failed:", error);
        }).finally(() => {
            setIsPopupOpen(false)
        });
    }

    const addLastdayPrasadamCharges = () => {
        setIsPopupOpen(true)
        setConfirmMessage("Adding the prasadam charges for last day please wait.")
        ApiRequest.handlePostApiRequest('/admin/lastday/prasadam').then(() => {
            setConfirmMessage("Done with the adding prasadam charges for dastday")
        }).catch(error => {
            console.error("Process failed:", error);
        }).finally(() => {
            setIsPopupOpen(false)
        });
    }

    const captureTransaction = () => {
        setPopupType('confirm');
        setConfirmMessage('Are you sure you want to capture this transaction as paid?')
        setIsPopupOpen(true);
        setPopupAction(() => async () => {
            try {
                setConfirmMessage('Started capturing.... please wait')
                const response = await ApiRequest.handlePostApiRequest('/admin/capture/all/payments', {}, {});
                setPaymentHistoryDTOs(response)
                setConfirmMessage('Done..!!!')
                setIsPopupOpen(false);
                setPopupType('error');
            } catch (error) {
                alert(error.message)
                alert('Could not capture the payment as paid')
            }
        });
    }

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString(undefined, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });
    };

    return (
        <ThemeProvider theme={theme}>
            <ValidationPopup
                isOpen={isPopupOpen}
                message={popupType === 'error' ? error : confirmMessage}
                type={popupType}
                onConfirm={popupAction}
                onCancel={() => {
                    setIsPopupOpen(false);
                    setPopupType('error');
                    setError('')
                }}
            />
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <Typography variant="h6" gutterBottom >
                    <strong>This button will update the payment status of all the registration</strong>
                </Typography>
                <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                    {LoginService.isAdmin() &&
                        <>
                            <Button
                                variant="contained"
                                onClick={() => updateAllPaymentStatus()}
                                size="small" // Make the button small
                                sx={{
                                    marginLeft: 'auto' // Push the button to the right side
                                }}
                            >
                                Update the payment status
                            </Button>
                        </>}
                </div>
            </Paper>

            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <Typography variant="h6" gutterBottom >
                    <strong>This button will add the prasadam charges for last day</strong>
                </Typography>
                <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                    {LoginService.isAdmin() &&
                        <>
                            <Button
                                variant="contained"
                                onClick={() => addLastdayPrasadamCharges()}
                                size="small" // Make the button small
                                sx={{
                                    marginLeft: 'auto' // Push the button to the right side
                                }}
                            >
                                Add lastday prasadam charges
                            </Button>
                        </>}
                </div>
            </Paper>

            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <Typography variant="h6" gutterBottom >
                    <strong>This button will add the prasadam charges for driver if the transportation is own arrangment</strong>
                </Typography>
                <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                    {LoginService.isAdmin() &&
                        <>
                            <Button
                                variant="contained"
                                onClick={() => addDriverPrasadamCharges()}
                                size="small" // Make the button small
                                sx={{
                                    marginLeft: 'auto' // Push the button to the right side
                                }}
                            >
                                Add driver prasadam charges
                            </Button>
                        </>}
                </div>
            </Paper>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <Typography variant="h6" gutterBottom >
                    <strong>This button will download of the data for room and members</strong>
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                        {LoginService.isAdmin() &&
                            <>
                                <Button
                                    variant="contained"
                                    onClick={() => downloadMembers()}
                                    size="small" // Make the button small
                                    sx={{
                                        marginLeft: 'auto' // Push the button to the right side
                                    }}
                                >
                                    Download members
                                </Button>
                            </>}
                    </div>
                    <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                        {LoginService.isAdmin() &&
                            <>
                                <Button
                                    variant="contained"
                                    onClick={() => downloadRooms()}
                                    size="small" // Make the button small
                                    sx={{
                                        marginLeft: 'auto' // Push the button to the right side
                                    }}
                                >
                                    Download rooms
                                </Button>
                            </>}
                    </div>
                </Typography>
            </Paper>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <strong>This button will capture all the delayed transactions</strong>
                    <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                        {LoginService.isAdmin() &&
                            <>
                                <Button
                                    variant="contained"
                                    onClick={() => captureTransaction()}
                                    size="small" // Make the button small
                                    sx={{
                                        marginLeft: 'auto' // Push the button to the right side
                                    }}
                                >
                                    Capture delayed
                                </Button>
                            </>}
                    </div>
                </Typography>
                {paymentHistoryDTOs.map((transaction, index) => (
                    <Box key={index} mb={1}>
                        {/* <Typography variant="body1"><strong>{`Transaction ${index + 1}`}</strong></Typography> */}
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span><strong>{`Transaction ${index + 1}`}</strong></span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Transaction Id:</span>
                            <span>{transaction.paymentId}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Amount Paid:</span>
                            <span>{transaction.amountPaid}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Amount Accounted:</span>
                            <span>{transaction.amountAccounted}</span>
                        </Typography>
                        {transaction.amountRefunded > 0 &&
                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Amount Refunded:</span>
                                <span>{transaction.amountRefunded}</span>
                            </Typography>}
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Created at:</span>
                            <span>{formatTimestamp(transaction.creationTimestamp)}</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Finished at:</span>
                            <span>{formatTimestamp(transaction.successfulTimestamp)}</span>
                        </Typography>
                    </Box>
                ))}
            </Paper>
        </ThemeProvider>
    );
};

export default AdminActions;
