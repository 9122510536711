import { Accordion, AccordionDetails, AccordionSummary, Box, Container, CssBaseline, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { sponsorshipItems } from '../../constants/jsons/sponsorship-items';
import SponsorshipItem from './SponsorshipItem';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
    typography: {
        fontFamily: 'Roboto', // Set your preferred font family
        htmlFontSize: 16, // Set the base font size
        body1: {
            fontSize: '1rem', // Default font size for body text
            lineHeight: 1.5, // Default line height for body text
        },
        body2: {
            fontSize: '0.875rem', // Default font size for secondary text (e.g., helper text)
            lineHeight: 1.5, // Default line height for secondary text
            lang: 'en', // Default language for all Typography components
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '1rem',
        maxWidth: 'md', // Set maximum width based on the theme's breakpoints
        margin: 'auto', // Center the content horizontally
    },
    card: {
        // marginBottom: theme.spacing(2),
        padding: '0rem'
    },
    heading: {
        fontSize: theme.typography?.fontSize || '1rem',
        fontWeight: theme.typography?.fontWeightRegular || 'normal',
    },
    comment: {
        fontStyle: 'italic',
    },
    item: {
        padding: '0.2rem'
    },
    emoji: {
        fontSize: '1.5rem', // Adjust the size of the emoji here
        marginRight: '0.5rem', // Add some spacing between the emoji and the text
    },
}));

const SponsorshipPage = ({ setTabIndex }) => {

    const classes = useStyles();

    return (
        <ThemeProvider theme={theme} >
            <div className={classes.root}>

                <Container maxWidth="md" sx={{ marginTop: 4, overflow: 'auto' }}>
                    <div className="summary-container">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'left',
                            }}
                        >
                            <Typography component="h1" variant="h4" gutterBottom>
                                Haridwar & Badrinath Yatra 2025 Sponsorship
                            </Typography>
                            <Typography variant="body1" paragraph>
                                <p>
                                    Dear Prabhujis and Matajis, <br />
                                    Hare Krishna. Please accept our humble obeisances. All glories to Guru Maharaja and Srila Prabhupada.
                                </p>
                            </Typography>
                            <Typography variant="body1" paragraph>
                                <p>
                                    We are in the process of completing arrangements for the upcoming Haridwar & Badrinath Yatra 2025 along with HH Romapada Maharaja & HH Bhakti Caitanya Maharaja.
                                </p>
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <p>
                                    In the past we have had some devotees approaching the organizers & area coordinators with a wish to sponsor a meal to feed the vaisnavas on a particular day,
                                    or otherwise to cover some portion of the yatra fees.
                                    Many devotees came forward & gave sponsorship which were useful in reducing the overall yatra fees.
                                    We are very grateful to all the devotees who sponsored.
                                </p>
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <p>
                                    Accordingly we are providing you here the itemized costs for prasadam & other expenses.
                                    This is not being done merely as an appeal to you to step forward and help us cover expenses.
                                    It is for your edification, if such an inspiration arises.
                                </p>
                            </Typography>
                            <Box sx={{ width: '100%' }}>
                                {/* {sponsorshipItems.map(item => (
                                    <SponsorshipItem key={item.id} item={item} />
                                ))} */}
                                {sponsorshipItems.map((item, index) => (
                                    <Accordion key={item.id}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel${item.id}-content`}
                                            id={`panel${item.id}-header`}
                                        >
                                            <Typography className={classes.heading}> {index + 1} - {item.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <SponsorshipItem item={item} setTabIndex={setTabIndex} />
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </Box>
                            <Box mt={4} />
                            <Typography variant="body1" paragraph>
                                We request you to kindly confirm your sponsorship before <b>1st August 2025.</b>
                                This will help us to decide on any reduction that is possible in the yatra cost per devotee based on the total sponsorship amount received.
                                Accordingly, registration fees will reflect a more accurate amount for overall expenditures.
                                This time we will be completing the entire registration process in advance by <b>August 15th 2025.</b>
                                Therefore your confirmation of sponsorship by 1st August 2025 will be most helpful.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                If you desire to assist with sponsoring prasadam & other expenses cost in full or part, please contact your Area coordinator or us.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                Fees per attendee (all of the above plus bus, etc), other than your accommodation costs, will be sent to you soon.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                Sponsorship amount can be remitted to your respective area coordinators or upon your arrival for the yatra.
                                In case some of you wish to confirm the sponsorship after 1st August 2025, you are still welcome to do so.
                                In other words we will receive the sponsorship right up to the end of yatra.
                            </Typography>

                            {/* <Typography variant="body1" paragraph>
                                <b>Note : Special Badrinath Silver coins souvenir will be given by maharaja to all sponsors above Rs. 20,000</b>
                            </Typography> */}

                            <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                Thanking you.<br />
                                Your servant,<br />
                                Padmanabha Das & Guru Das<br />
                            </Typography>
                        </Box>
                    </div >
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default SponsorshipPage;
