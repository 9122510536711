import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import ApiRequest from 'services/api.service';
import AddUpdateRoomChoiceDialog from './AddUpdateRoomChoiceDialog';
import DestinationView from './DestinationView';

const RoomDetailsForm = ({
  configData,
  registration,
  setRegistration,
  nextStep,
  prevStep,
  defaultExpanded = true,
  viewOnly = false
}) => {
  // const [roomChoices, setRoomChoices] = useState({});
  // const [roomsLoading, setRoomsLoading] = useState(true);
  const [dialogData, setDialogData] = useState({
    open: false,
    destination: null,
    room: null,
  });

  const destinations = (configData.destinations || []).map(destination => ({
    ...destination,
    options: destination.options.reduce((acc, option) => {
      acc[option.type] = option;
      return acc;
    }, {}),
  }));

  const handleOpenDialog = (destination, room = null) => {
    setDialogData({
      open: true,
      destination,
      room,
    });
  };

  const handleCloseDialog = () => {
    setDialogData({
      open: false,
      destination: null,
      room: null,
    });
  };

  const handlePrev = (event) => {
    event.preventDefault();
    prevStep();
  };

  const handleSubmit = () => {
    if (Object.values(registration.roomChoices).flat().length === 0) {
      alert('Please add at least one room before proceeding.');
    } else {
      nextStep();
    }
  };

  const handleDelete = async (room) => {
    const confirmed = window.confirm(`Are you sure you want to delete ${room.displayText}?`);
    if (!confirmed) return;

    try {
      const updatedChoices = await ApiRequest.handleDeleteApiRequest(
        '/roomPreference',
        { roomId: room.id, regId: registration.id }
      );

      if (!updatedChoices) {
        alert('Failed to delete the room choice. Please try again later.');
        return;
      }

      setRoomChoices(updatedChoices);
      alert(`${room.displayText} has been successfully deleted.`);
    } catch (error) {
      console.error('Error while deleting the room choice:', error);
      alert('An error occurred while deleting the room choice. Please try again.');
    }
  };

  const setRoomChoices = (roomChoices) => {
    const updatedRoomChoices = {
      ...registration,
      roomChoices: roomChoices, // Use computed property name syntax to set the key dynamically
    };
    setRegistration(updatedRoomChoices);
  }

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [expanded, setExpanded] = useState(defaultExpanded)

  return (
    <>
      <div className="page-section">
        <Box>
          <Accordion key='room_preferences' sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary key={'room_preferences'} expandIcon={<ExpandMoreIcon />}>
              <Typography variant="title"><strong>Room preferences</strong></Typography>
            </AccordionSummary>
            <AccordionDetails key='room_preferences_details'>
              {destinations.map((destination, destinationNumber) => (
                <DestinationView
                  key={destinationNumber}
                  destination={destination}
                  destinationNumber={destinationNumber}
                  roomChoices={registration.roomChoices[destinationNumber]}
                  handleOpenDialog={handleOpenDialog}
                  handleDelete={handleDelete}
                  viewOnly={viewOnly}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
      {!viewOnly && (
        <>
          <Divider />
          <div className="registration-form-content-actions">
            <Button
              variant="contained"
              onClick={handlePrev}
              sx={{ mt: 1 }}
              startIcon={<KeyboardArrowLeftIcon className="xyz-white-icon" />}
            >
              {isSmallScreen ? <span style={{ fontSize: '8px' }}>Back</span> : 'Back'}
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ mt: 1 }}
              endIcon={<KeyboardArrowRightIcon className="xyz-white-icon" />}
            >
              {isSmallScreen ? <span style={{ fontSize: '8px' }}>Next</span> : 'Next'}
            </Button>
          </div>
        </>
      )}
      <AddUpdateRoomChoiceDialog
        openDialog={dialogData.open}
        handleCloseDialog={handleCloseDialog}
        destination={dialogData.destination}
        room={dialogData.room}
        setRoomChoices={setRoomChoices}
        regId={registration.id}
      />
    </>
  );
};

export default RoomDetailsForm;
