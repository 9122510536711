import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const NavBar = ({ currentStep }) => {
  const theme = useTheme();
  const isPhoneSize = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is phone

  const steps = ['Info', 'Member Details', 'Room Details', 'Summary', 'Payment'];
  const activeStep = currentStep - 1;

  const stepRef = useRef(null);

  useEffect(() => {
    if (stepRef.current) {
      const containerWidth = stepRef.current.offsetWidth;
      const stepWidth = containerWidth / steps.length;
      const scrollOffset = activeStep * stepWidth;
      stepRef.current.scrollLeft = scrollOffset;
    }
  }, [activeStep, steps.length]);

  return (
    <div style={{ overflowX: 'auto' }} ref={stepRef}>
      <Box
        sx={{
          width: '100%',
        }}
        className="registration-form-stepper"
      >
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            const stepIsCompleted = activeStep > index;
            return (
              <Step key={label} completed={stepIsCompleted}>
                <StepLabel {...labelProps}>
                  {/* Apply smaller font size for phones */}
                  {isPhoneSize ? <Typography variant="caption">{label}</Typography> : label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>

          </React.Fragment>
        )}
      </Box>
    </div>
  );
};

export default NavBar;
