import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import ShortSummary from '../summaries/ShortSummary';
import LegalResponsibility from 'components/pages/legal-responsibility/LegalResponsibility';
import { ValidationPopup } from 'components/generics/ValidationPopup';
import ApiRequest from 'services/api.service';

const SummaryTab = ({ configData, registration, setRegistration, nextStep, prevStep }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [internalComments, setInternalComments] = useState(registration.comments)
  const [acceptTermsAndCondition, setAcceptTermsAndCondition] = useState(registration.acceptTermsAndCondition)

  const handleCommentsChange = (event) => {
    setInternalComments(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setAcceptTermsAndCondition(event.target.checked);
  };

  const handleSubmit = async () => {
    if (acceptTermsAndCondition) {
      const updatedRegistration = await ApiRequest.handlePostApiRequest(
        '/registration/summary',
        {},
        { regId: registration.id, comments: internalComments, acceptTermsAndCondition: true }
      );
      setRegistration(updatedRegistration)
      nextStep();
    } else {
      alert("Please accept the terms and conditions")
      return;
    }
  }

  return (
    <>
      <div className="page-section">
        <div className="rps-flex section-header">
          <h4 className="flex-one">Yatra registration Summary </h4>
        </div>
        <ShortSummary
          registration={registration}
          defaultExpanded={true}
          configData={configData}
        />
        <LegalResponsibility />
        <div className="room-form">
          <h3>Comments</h3>
          <div className='form-group'>
            <textarea
              style={{ width: '100%', boxSizing: 'border-box' }}
              value={internalComments}
              onChange={handleCommentsChange}
              placeholder="Add any comments here"
            ></textarea>
          </div>
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptTermsAndCondition}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="I accept the terms and conditions"
        />
      </div>
      <div className="registration-form-content-actions">
        <Button variant="contained" onClick={prevStep} sx={{ mt: 1 }} startIcon={<KeyboardArrowLeftIcon className='xyz-white-icon' />}>
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>Back</span> : 'Back'}
        </Button>
        <Button variant="contained" onClick={handleSubmit} sx={{ mt: 1 }} endIcon={<KeyboardArrowRightIcon className='xyz-white-icon' />}>
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>Save and proceed</span> : 'Save and proceed'}
        </Button>
      </div>
    </>
  );
};

export default SummaryTab;
