import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, FormHelperText, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import LoginService from 'services/login.service';
import PaymentButton from './PaymentButton';
import RegistrationBill from '../summaries/RegistrationBill';

const PaymentTab = ({ registration, from, prevStep }) => {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  /*
  * This flow is only for admin may need to change this based on admin selection
  */
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    setIsAdmin(LoginService.isAdmin())
  }, []);
  
  // Calculate GST and Convenience fee for all members
  const members = registration.members;
  const total = members.reduce((total, member) => total + member.lateFees + member.registrationCharges, 0) - registration.onlineAmount - registration.miscPaymentAmount;
  const [amountToPay, setAmountToPay] = useState(total);

  const handleAmountChange = (event) => {
    const value = parseInt(event.target.value, 10); // Parse the value as an integer
    setAmountToPay(isNaN(value) ? 0 : value); // Ensure value is a valid integer
  };

  return (
    <>
      <div className="page-section">
        <Box>
            <RegistrationBill registration={registration} defaultExpanded={true}/>
        </Box>
        <FormHelperText style={{ fontSize: '0.8rem', color: 'blue' }}>
          Your final bill may come from {registration.approxBill} based on your current selection.<br />
          Registration amount will be deducted from your final bill excluding GST and convenience fees.<br />
        </FormHelperText>
      </div>
      <div className="registration-form-content-actions">
        <Button variant="contained" onClick={prevStep} sx={{ mt: 1 }} startIcon={<KeyboardArrowLeftIcon className='xyz-white-icon' />}>
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>Back</span> : 'Back'}
        </Button>
        {isAdmin &&
          <TextField
            id="amount"
            name="amount"
            label="Amount"
            variant="outlined"
            value={amountToPay}
            onChange={handleAmountChange}
            sx={{
              minWidth: '50px',
              minHeight: '40px',
            }}
          />}
        <PaymentButton
          regId={registration.id}
          from={from}
          amount={amountToPay}
        />
      </div>
    </>
  );
};

export default PaymentTab;
