import {
    Map as MapIcon
} from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, IconButton, TextField, Typography } from '@mui/material';
import { ValidationPopup } from 'components/generics/ValidationPopup';
import { Field, Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import ApiRequest from 'services/api.service';
import LoginService from 'services/login.service';
import { getDay } from 'services/utils';
import * as Yup from 'yup';

const StyledIcon = (props) => {
    return <Icon style={{ display: 'initial' }} {...props} />;
};

const validationSchema = Yup.object().shape({
    room: Yup.string().required('Room is required'),
});

const initialValues = {
    room: '',
};

const RoomAllocationCard = ({ defaultExpanded, isPrint, roomView, registration, updateSingleRegistration }) => {

    const [error, setError] = useState('');
    const [confirmMessage, setConfirmMessage] = useState('')
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupAction, setPopupAction] = useState('error');
    const [popupType, setPopupType] = useState('error');

    const [expanded, setExpanded] = useState(defaultExpanded && true);
    useEffect(() => {
        setExpanded(defaultExpanded || roomView);
    }, [roomView]);
    const [suggestions, setSuggestions] = useState([]);
    const [otherSuggestions, setOtherSuggestions] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [popupInprogress, setPopupInprogress] = useState(false);
    const [generateBill, setGenerateBill] = useState(registration.generateBill);

    const hadleRoomFinalization = async () => {
        try {
            const generateBillStatus = !generateBill
            setGenerateBill(generateBillStatus)
            const response = await ApiRequest.handlePostApiRequest("/admin/roomAllocation/finalizeRoom", {}, { regId: registration?.id, generateBill: generateBillStatus });
            updateSingleRegistration(response);
        } catch (error) {
            console.error("Error fetching other room suggestions:", error);
        }
    }

    const fetchOtherSuggestions = async () => {
        try {
            const response = await ApiRequest.handlePostApiRequest("/admin/roomAllocation/otherSuggestions", selectedRooms, { regId: registration?.id });
            setOtherSuggestions(response);
        } catch (error) {
            console.error("Error fetching other room suggestions:", error);
        }
    };

    const fetchRoomSuggestions = async () => {
        try {
            const response = await ApiRequest.handlePostApiRequest("/admin/roomAllocation/suggestions", {}, { regId: registration?.id });
            setSuggestions(response.suggested);
        } catch (error) {
            console.error("Error fetching other room suggestions:", error);
        }
    };

    const handleRemoveRoom = async (index) => {
        setPopupType('confirm');
        setConfirmMessage('Are you sure you want to delete this room allocation?')
        setIsPopupOpen(true);
        setPopupAction(() => async () => {
            try {
                setConfirmMessage('Started deallocating the room.... please wait')
                setPopupInprogress(true);
                const room = registration.roomsAllocated[index];
                try {
                    const response = await ApiRequest.handlePostApiRequest("/admin/roomAllocation/removeRoom", {}, { roomAllocatedId: room.id, regId: registration?.id });
                    updateSingleRegistration(response);
                } catch (error) {
                    console.error("Error removing room:", error);
                }
                setPopupInprogress(false);
                setConfirmMessage('Done..!!!')
                setIsPopupOpen(false);
                setPopupType('error');
            } catch (error) {
                alert(error.message)
                alert('Could not capture the payment as paid')
            }
        });
    };

    const handleRoomAllocation = async (room) => {
        if (!room || !room.roomInventory) return;
        setSelectedRooms((prevSelectedRooms) => [...prevSelectedRooms, room]);
        setSuggestions((prevSuggestions) => prevSuggestions.filter((suggestion) => suggestion.id !== room.id));
        setOtherSuggestions((prevOtherSuggestions) => prevOtherSuggestions.filter((otherSuggestion) => otherSuggestion.id !== room.id));
    };

    const handleRoomsAllocation = async () => {
        if (!selectedRooms || selectedRooms.length === 0) return;
        try {
            const roomsPayload = selectedRooms.map(room => ({
                roomId: room.id,
                inventoryId: room.roomInventory.id
            }));
            const response = await ApiRequest.handlePostApiRequest("/admin/roomAllocation/allocateRooms",
                roomsPayload, { regId: registration?.id });
            // fetchRoomSuggestions();
            updateSingleRegistration(response);
            setSelectedRooms([]); // Clear selected rooms after allocation
        } catch (error) {
            console.error("Error allocating rooms:", error);
        }
    };

    const inputRef = useRef(null);

    function underscoreToTitleCase(str) {
        return str
            .split('_') // Split the string by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
            .join(' '); // Join them back together with spaces
    }

    const DisplayRoom = ({ room }) => {
        const { hotelName, location, contactNumber, checkIn, checkOut, optionType, destinationNumber, address, googleMapAddress } = room.roomInventory;
        return <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="title">
                        {`${room.id}, ${underscoreToTitleCase(optionType)}, ${hotelName}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="body2" color="textSecondary">
                        {`Destination - ${destinationNumber + 1}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="body2" color="textSecondary">
                        {`${location}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="body2" color="textSecondary">
                        {`${getDay(checkIn)} - ${getDay(checkOut)}`}
                        {/* {`${new Date(checkIn).toLocaleDateString()} - ${new Date(checkOut).toLocaleDateString()}`} */}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <StyledIcon>
                        <a href={googleMapAddress} target="_blank" rel="noopener noreferrer">
                            Map
                            <MapIcon />
                        </a>
                    </StyledIcon>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <Typography variant="body2" color="textSecondary">
                        {`${contactNumber}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Typography variant="body2" color="textSecondary">
                        {`${address}`}
                    </Typography>
                </Grid>
            </Grid>
        </>
    }


    return (
        <>
            <ValidationPopup
                isOpen={isPopupOpen}
                message={popupType === 'error' ? error : confirmMessage}
                type={popupType}
                onConfirm={popupAction}
                loading={popupInprogress}
                onCancel={() => {
                    setIsPopupOpen(false);
                    setPopupType('error');
                    setError('')
                }}
            />
            <Accordion key='acc_room' sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary key={'acc_summary_room'} expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="title"><strong>Room allocated</strong></Typography>
                </AccordionSummary>
                <AccordionDetails key='acc_details_room'>
                    {registration.roomsAllocated.map((room, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                            <DisplayRoom room={room.room} />
                            {LoginService.isAdmin() && roomView && (
                                <IconButton onClick={() => handleRemoveRoom(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </div>
                    ))}
                    {LoginService.isAdmin() && !isPrint && (
                        <div className="registration-form-content-actions" style={{ 'justify-content': 'space-between' }}>
                            <Button variant="contained" color="primary" onClick={() => { setDialogOpen(true); fetchRoomSuggestions() }} style={{ marginTop: 16 }}>
                                Allocate Room
                            </Button>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={generateBill}
                                        onChange={hadleRoomFinalization}
                                        color="primary"
                                    />
                                }
                                label="Done with room allocation"
                            />
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="md">
                <DialogTitle>Select a Room</DialogTitle>
                <DialogContent>
                    <Typography variant="h6" style={{ marginTop: 16 }}>Temporary Selected Rooms</Typography>
                    {selectedRooms.map((room, index) => {
                        return <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                            <DisplayRoom room={room} />
                            <IconButton onClick={() => setSelectedRooms(selectedRooms.filter((_, i) => i !== index))}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    })}

                    <Typography variant="h6" style={{ marginTop: 16 }}>Suggested Rooms</Typography>
                    {suggestions.map((room, index) => {
                        if (room.roomInventory) {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                                    <DisplayRoom room={room} />
                                    <IconButton
                                        onClick={() => handleRoomAllocation(room)}
                                    >
                                        <AddBoxIcon />
                                    </IconButton>
                                </div>
                            );
                        }
                        return '';
                    })}

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { resetForm }) => {
                            resetForm();
                        }}
                    >
                        {({ errors, touched, setFieldValue }) => (
                            <Form>
                                <Typography variant="h6" style={{ marginTop: 16 }}>Other Suggested Rooms</Typography>
                                <Field
                                    as={Autocomplete}
                                    options={otherSuggestions}
                                    getOptionLabel={(room) => {
                                        if (room.roomInventory) {
                                            return `${room.id} - ${room.roomInventory.hotelName} - ${room.roomInventory.location} - Check-in: ${new Date(room.roomInventory.checkIn).toLocaleDateString()} - Check-out: ${new Date(room.roomInventory.checkOut).toLocaleDateString()}`
                                        }
                                        return '';
                                    }}
                                    onFocus={fetchOtherSuggestions}
                                    onChange={(event, value) => {
                                        if (value && value.roomInventory) {
                                            handleRoomAllocation(value);
                                            setFieldValue('room',
                                                `${value.id} - ${value.roomInventory.hotelName} - ${value.roomInventory.location} - Check-in: ${new Date(value.roomInventory.checkIn).toLocaleDateString()} - Check-out: ${new Date(value.roomInventory.checkOut).toLocaleDateString()}`
                                            );
                                            if (inputRef.current) {
                                                inputRef.current.blur(); // Remove focus from the input element
                                            }
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Suggestions"
                                            fullWidth
                                            error={errors.room && touched.room}
                                            helperText={errors.room && touched.room && errors.room}
                                            inputRef={inputRef}
                                        />
                                    )}
                                    renderOption={(props, room) => (
                                        <li {...props}>
                                            <DisplayRoom room={room} />
                                        </li>
                                    )}
                                />
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
                <div className="registration-form-content-actions" style={{ 'justify-content': 'end' }}>
                    <DialogActions>
                        <Button onClick={() => {
                            setDialogOpen(false);
                            setSelectedRooms([]);
                        }} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            setDialogOpen(false);
                            handleRoomsAllocation();
                        }} color="primary" variant="contained">
                            Confirm Allocation
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </>
    );
};

export default RoomAllocationCard;
