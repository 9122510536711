import AddPayment from 'components/registration/AddPayment';
import FinalBill from './FinalBill';
import FinancialSummary from './FinancialSummary';
import RegistrationBill from './RegistrationBill';
import ShortSummary from './ShortSummary';
import TransactionSummary from './TransactionSummary';
import { Paper, Typography } from '@mui/material';
import RoomAllocationCard from './RoomAllocationCard';

const FullSummary = ({ registration, configData, defaultExpanded, from, updateSingleRegistration, roomView }) => {

    return (
        <>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span><strong>Comments : </strong>{registration.comments}</span>
                </Typography>
            </Paper>
            <ShortSummary
                defaultExpanded={defaultExpanded}
                configData={configData}
                updateSingleRegistration={updateSingleRegistration}
                roomView={roomView}
                registration={registration}
            />
            <RoomAllocationCard
                roomDetails={registration.roomDetails}
                defaultExpanded={defaultExpanded}
                roomView={roomView}
                destinations={configData.destinations}
                registration={registration}
                updateSingleRegistration={updateSingleRegistration}
            />
            {!roomView && <>
                <RegistrationBill
                    registration={registration}
                    defaultExpanded={defaultExpanded}
                    isFullSummary={true}
                />
                <TransactionSummary
                    registrationDTO={registration}
                    defaultExpanded={defaultExpanded}
                    updateSingleRegistration={updateSingleRegistration}
                />
                {registration.generateBill &&
                    <FinalBill
                        registrationDTO={registration}
                        configDestinations={configData.destinations}
                        defaultExpanded={defaultExpanded}
                        updateSingleRegistration={updateSingleRegistration}
                    />
                }
                {registration.registrationStatus === "REGISTERED" &&
                    <AddPayment
                        registrationDTO={registration}
                        defaultExpanded={defaultExpanded}
                        from={from}
                        updateSingleRegistration={updateSingleRegistration}
                    />}
            </>}
        </>
    );
};

export default FullSummary;
