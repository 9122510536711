import React from 'react';
import Signup from './Signup';

const UpdateProfile = () => {

    return (
        <Signup isProfileUpdate={true} buttonText={"Update profile"}/>
    )
}

export default UpdateProfile;