import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import ForgotResetPassword from './components/authentication/ForgotResetPassword';
import Login from './components/authentication/Login';
import Signup from './components/authentication/Signup';
import Info from './components/registration/Info';

import SettlementTable from 'components/accounts/SettlementTable';
import AllRegistration from 'components/admin-pages/AllRegistration';
import PricingPolicy from 'components/pages/pricing-policy/PricingPolicy';
import PrivacyPolicy from 'components/pages/privacy-policy/PrivacyPolicy';
import RefundPolicy from 'components/pages/refund-policy/RefundPolicy';
import TermsAndConditions from 'components/pages/terms-and-conditions/TermsAndConditions';
import { useNavigate } from "react-router-dom";
import YatraSchedule from './components/generics/YatraSchedule';
import TopNavigationBar from './components/generics/topNavigationBar/TopNavigationBar';
import ContactUsPage from './components/pages/contact-us/ContactUs';
import Home from './components/pages/home/Home';
import AddUpdateYatra from './components/post_yatra/AddUpdateYatra';
// import RegistrationHomePage from './components/registration/RegistrationHomePage';
import SettlementRecordTable from 'components/accounts/SettlementRecordTable';
import Sponsorship from 'components/accounts/Sponsorship';
import AdminActions from 'components/admin-pages/AdminActions';
import AdminStatistics from 'components/admin-pages/AdminStatistics';
import AllRegistrationLite from 'components/admin-pages/AllRegistrationLite';
import RoomInventory from 'components/admin-pages/RoomInventory';
import Faqs from 'components/pages/faqs/faqs';
import HealthRecommendation from 'components/pages/health-recommendation/HealthRecommendation';
import LegalResponsibility from 'components/pages/legal-responsibility/LegalResponsibility';
import YatraPricing from 'components/pages/yatra-pricing/YatraPricing';
import QuoteCalculator from 'components/registration/QuoteCalculator';
import RegistrationForm from 'components/registration/RegistrationForm';
import RegistrationList from 'components/registration/RegistrationList';
import LoginService from './services/login.service';
import UpdateProfile from 'components/authentication/UpdateProfile';

const App = () => {
  const [loginStatus, setLoginStatus] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAccountsPermission, setIsAccountsPermission] = useState(false);

  useEffect(() => {
    setLoginStatus(LoginService.isUserLoggedIn());
    setIsAdmin(LoginService.isAdmin());
    setIsAccountsPermission(LoginService.isAccountsPermission());
  }, []);

  const navigate = useNavigate();

  const logOut = () => {
    LoginService.handleLogout();
    setLoginStatus(false);
    setIsAdmin(false);
    setIsAccountsPermission(false);
    navigate("/")
  };

  return (
    <div className="rps-flex-column" id="rps-app-container">
      {/* <div className="rps-flex" id="rps-app-header"> */}
      <TopNavigationBar loginStatus={loginStatus} logOut={logOut} isAdmin={isAdmin} isAccountsPermission={isAccountsPermission}/>
      {/* <Alert severity="warning">
        Registration has not started yet. Please do not proceed with any payment.
      </Alert> */}
      {/* </div> */}
      <Routes>
        <Route exact path={'/'} element={<Home loginStatus={loginStatus} />} />
        <Route exact path={'/home'} element={<Info />} />
        <Route exact path="/login" element={<Login setLoginStatus={setLoginStatus} setIsAdmin={setIsAdmin} setIsAccountsPermission={setIsAccountsPermission} />} />
        <Route exact path="/registration-list" element={<RegistrationList />} />
        <Route exact path="/registration-form" element={<RegistrationForm />} />
        <Route exact path="/schedule" element={<YatraSchedule />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/reset-password" element={<ForgotResetPassword />} />
        <Route exact path="/update-profile" element={<UpdateProfile />} />
        <Route exact path="/post-yatra" element={<AddUpdateYatra />} />
        <Route exact path="/sponsorship" element={<Sponsorship />} />
        <Route exact path="/admin-actions" element={<AdminActions />} />

        <Route exact path="/contact-us" element={<ContactUsPage />} />
        <Route exact path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route exact path='/pricing-policy' element={<PricingPolicy />} />
        <Route exact path='/refund-policy' element={<RefundPolicy />} />
        <Route exact path='/legal-responsibility' element={<LegalResponsibility />} />
        <Route exact path='/yatra-pricing' element={<YatraPricing />} />
        {/* <Route exact path='/health-recommendation' element={<HealthRecommendation />} /> */}
        <Route exact path='/faqs' element={<Faqs />} />
        
        <Route exact path='/all-registration' element={<AllRegistration />} />
        <Route exact path='/all-registration-lite' element={<AllRegistrationLite />} />
        <Route exact path='/admin-stats' element={<AdminStatistics />} />
        <Route exact path='/room-inventory' element={<RoomInventory />} />
        <Route exact path='/yatra-quatation' element={<QuoteCalculator />} />
        <Route exact path='/settlements' element={<SettlementTable />} />
        <Route exact path='/settlement-record' element={<SettlementRecordTable />} />
      </Routes>
    </div>
  );
};

export default App;
