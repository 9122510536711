import { Button, Typography, createTheme } from '@mui/material';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiRequest from '../../services/api.service';
import RegistrationCard from './RegistrationCard';
import WhatsAppGroupLink from './WhatsappGroupLink';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const useStyles = makeStyles({
    accordion: {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Initial shadow with elevated effect
        borderRadius: '20px',
        transition: 'box-shadow 0.3s', // Smooth transition for box-shadow change
        margin: '15px 0', // Margin added for top and bottom
        cursor: 'pointer',
        '&:last-child': {
            marginBottom: 0,
        },
        '&:hover': {
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.3)', // Increased shadow on hover
            backgroundColor: 'rgba(0, 0, 0, 0.1)', // Darken background color on hover
        },
    },
    accordionSummary: {
        borderRadius: '20px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
});


const RegistrationList = () => {
    const classes = useStyles();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [registrations, setRegistrations] = useState([]);
    const [isConfigLoading, setIsConfigLoading] = useState(true);
    const [isRegistrationLoading, setIsRegistrationLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.pathname

    const [configData, setConfigData] = useState({});
    useEffect(() => {
        ApiRequest.handleGetApiRequest('/config').then((response) => {
            setConfigData(response);
            setIsConfigLoading(false);
        });
    }, []);

    const handleReloadData = () => {
        ApiRequest.handleGetApiRequest('/registration').then(
            (response) => {
                setRegistrations(response);
                setIsRegistrationLoading(false);
            }
        );
    }

    const updateSingleRegistration = (updatedRegistration) => {
        setRegistrations(prevList =>
            prevList.map(obj =>
                obj.id === updatedRegistration.id ? updatedRegistration : obj
            )
        );
    };

    useEffect(() => {
        handleReloadData()
    }, []);

    if (isConfigLoading || isRegistrationLoading) {
        return <div>Loading...</div>; // Render loading indicator while data is being fetched
    }

    const handleAddRegistration = () => {
        navigate('/registration-form', { state: { inputConfigData: configData, from: from } })
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <div>
                    <Container maxWidth="md" sx={{ marginTop: 4, overflow: 'auto' }}>
                        <div className="summary-container">
                            <div className="rps-flex" style={{ padding: "1em 0em" }}>
                                <Typography variant="h4">
                                    My Registrations
                                </Typography>
                            </div>
                            <div className="registration-form-content-actions">
                                <Button
                                    variant="contained"
                                    onClick={handleAddRegistration}
                                    sx={{
                                        width: '60%',
                                        alignItems: 'right',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    className={classes.accordion}
                                >
                                    {isSmallScreen ? (
                                        <Typography variant="body2">
                                            Register for the Yatra
                                        </Typography>
                                    ) : (
                                        <Typography variant="button">
                                            Register for the Yatra
                                        </Typography>
                                    )}
                                </Button>
                            </div>

                            <div id="registration-form-table">
                                {registrations.some(registration => registration.registrationStatus === "REGISTERED") && <WhatsAppGroupLink />}
                                {registrations.map((registration) => (
                                    <RegistrationCard registration={registration} 
                                        registrationData={registration}
                                        configData={configData}
                                        from={from} updateSingleRegistration={updateSingleRegistration}
                                        handleReloadData={handleReloadData}
                                    />
                                ))}
                            </div>
                        </div>
                    </Container>
                </div>
            </ThemeProvider>
        </>
    );
};

export default RegistrationList;
