import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Info = ({ nextStep, configData, from }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const navigate = useNavigate();

  if (!configData || !configData.rules) {
    return <div>Loading...</div>; // Or any other loading state representation
  }

  const rules = configData.rules;

  const handleBackToRegistration = () => {
    navigate(from);
  };

  return (
    <>
      <div className="registration-form-content-sec">
        {Object.entries(rules).map(([header, text], index) => (
          <div key={index} className="mb-1">
            <Typography variant="h6" component="h6" className="font-weight-bold mb-0">
              {header}
            </Typography>
            <Typography variant="body1">{text}</Typography>
          </div>
        ))}
      </div>
      <div className="registration-form-content-actions">
        <Button
          variant="contained"
          onClick={handleBackToRegistration}
          sx={{ mt: 1 }}
          startIcon={<KeyboardArrowLeftIcon className="xyz-white-icon" />}
        >
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>Back</span> : 'Back'}
        </Button>
        <Button
          variant="contained"
          onClick={nextStep}
          sx={{ mt: 1 }}
          endIcon={<KeyboardArrowRightIcon className="xyz-white-icon" />}
        >
          {isSmallScreen ? <span style={{ fontSize: '8px' }}>Next</span> : 'Next'}
        </Button>
      </div>
    </>
  );
};

export default Info;
