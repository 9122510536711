// App.js
import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginService from 'services/login.service';
import ApiRequest from '../../services/api.service';
import { isEqual } from '../../services/is.equal';
import Info from './Info';
import MemberDetailsForm from './MemberDetailsForm';
import NavBar from './NavBar';
import PaymentTab from './PaymentTab';
import RoomDetailsForm from './RoomDetailsForm';
import SummaryTab from './SummaryTab';

const RegistrationForm = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [registration, setRegistration] = useState(location.state?.registration || undefined);
  const inputConfigData = location.state?.inputConfigData;
  const from = location.state?.from;

  const [configData, setConfigData] = useState(inputConfigData || undefined);
  const [isConfigLoading, setIsConfigLoading] = useState(true);

  useEffect(() => {
    setIsConfigLoading(true);

    // Fetch config if not already available
    if (!configData) {
      ApiRequest.handleGetApiRequest('/config')
        .then((response) => {
          setConfigData(response);
        })
        .catch((error) => {
          console.error("Failed to fetch config:", error);
          // Handle error appropriately
        })
        .finally(() => {
          setIsConfigLoading(false);
        });
    } else {
      setIsConfigLoading(false);
    }

    // If registration is null, create a new one, but only after configData is loaded
    if (!registration && configData) {
      const defaultRegistration = {
        yatraId: configData.id,
      };
      ApiRequest.handlePostApiRequest('/registration', defaultRegistration)
        .then((response) => {
          if (response) {
            setRegistration(response)
          }
        })
        .catch((error) => {
          console.error("Failed to create registration:", error);
        });
    }
  }, [configData]); // Adding `configData` as a dependency

  const [currentStep, setCurrentStep] = useState(1);
  const [step, setStep] = useState(1);

  // Function to handle the beforeunload event
  const handleBeforeUnload = (event) => {
    // Display a confirmation message
    event.preventDefault();
    event.returnValue = 'Are you sure you want to leave? Your changes may not be saved.';
    // Set a flag for redirection after refresh
    sessionStorage.setItem('refreshed', 'true');
  };

  useEffect(() => {
    // Add the beforeunload event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Check if the 'refreshed' flag is set in sessionStorage on component mount
    if (sessionStorage.getItem('refreshed')) {
      // Perform the redirection
      navigate(from);
      // Optionally, clear the flag after redirecting
      sessionStorage.removeItem('refreshed');
    }

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate]);

  if (isConfigLoading && !registration) {
    return <div>Loading...</div>; // Render loading indicator while data is being fetched
  }

  const nextStep = () => {
    const newStep = currentStep + 1;
    setCurrentStep(newStep);
    setStep(step + 1);
  }
  const prevStep = () => {
    const newStep = currentStep - 1;
    setCurrentStep(newStep);
    setStep(step - 1);
  }

  if (!LoginService.isUserLoggedIn()) {
    navigate('/login')
  }

  return (

    <Container maxWidth="md" >
      <div class="registration-form">
        <div className="registration-form-content">
          <NavBar currentStep={currentStep} />
          {currentStep === 1 &&
            <Info
              configData={configData}
              from={from}
              nextStep={nextStep}
            />
          }
          {currentStep === 2 &&
            <MemberDetailsForm
              configData={configData}
              registration={registration}
              setRegistration={setRegistration}
              nextStep={nextStep} prevStep={prevStep} />
          }
          {currentStep === 3 &&
            <RoomDetailsForm
              configData={configData}
              registration={registration}
              setRegistration={setRegistration}
              nextStep={nextStep} prevStep={prevStep} />
          }
          {currentStep === 4 &&
            <SummaryTab
              configData={configData}
              registration={registration}
              setRegistration={setRegistration}
              nextStep={nextStep} prevStep={prevStep}
            />
          }
          {currentStep === 5 &&
            <PaymentTab
              registration={registration}
              setRegistration={setRegistration}
              from={from}
              prevStep={prevStep}
            />
          }
        </div>
      </div>
    </Container>
  );
};

export default RegistrationForm;
