import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import ApiRequest from 'services/api.service';
import LoginService from 'services/login.service';

const MemberView = ({
    member,
    memberIndex,
    handleEditMember,
    handleDeleteMember,
    setMembers,
    defaultExpanded = true,
    viewOnly = false,
}) => {

    function StatusTypography({ status }) {
        // Determine the color based on the status
        const getColor = (status) => {
            switch (status) {
                case 'REGISTERED':
                    return 'green';
                case 'CANCELLED':
                    return 'red';
                case 'NOT_REGISTERED':
                    return 'blue';
                default:
                    return 'default'; // default color if none of the above
            }
        };

        return (
            <Typography style={{ color: getColor(status) }}>
                {status}
            </Typography>
        );
    }

    const [expanded, setExpanded] = useState(defaultExpanded && true);
    const [memberStatus, setMemberStatus] = useState(member.status); // Default value for member status
    const isAdmin = LoginService.isAdmin();

    const handleMemberStatusChange = (event) => {
        setMemberStatus(event.target.value);
    };

    const handleSave = async () => {
        const confirmed = window.confirm(`Are you sure you want to update the member status to : ${memberStatus}?`);
        if (!confirmed) return;

        const members = await ApiRequest.handlePostApiRequest('/member/status', {}, { memberId: member.id, status: memberStatus });
        setMembers(members)
    };

    return (
        <Accordion key={'member' + memberIndex} sx={{ mb: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                            <strong>{member.name.toUpperCase()}</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <StatusTypography status={member.status} />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        {!viewOnly && handleEditMember && (member.status === 'NOT_REGISTERED' || isAdmin) ? (
                            <IconButton onClick={(event) => { event.stopPropagation(); handleEditMember(member); }} aria-label="edit">
                                <EditIcon />
                            </IconButton>
                        ) : (<></>)}
                        {!viewOnly && handleDeleteMember && member.status === 'NOT_REGISTERED' ? (
                            <IconButton onClick={(event) => { event.stopPropagation(); handleDeleteMember(member); }} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        ) : (<></>)}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    {isAdmin && !viewOnly &&
                        <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                            <Typography variant="subtitle1"><strong>Update member status</strong></Typography>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="member-status-label">Member Status</InputLabel>
                                <Select
                                    labelId="member-status-label"
                                    id="member-status-select"
                                    value={memberStatus}
                                    onChange={handleMemberStatusChange}
                                    label="Member Status"
                                    style={{ marginRight: '10px' }}
                                >
                                    <MenuItem value="NOT_REGISTERED">NOT_REGISTERED</MenuItem>
                                    <MenuItem value="REGISTERED">REGISTERED</MenuItem>
                                    <MenuItem value="CANCELLED">CANCELLED</MenuItem>
                                </Select>
                            </FormControl>
                            <IconButton onClick={handleSave} aria-label="save">
                                <SaveIcon />
                            </IconButton>
                        </Grid>}
                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }}><Typography variant="subtitle1"><strong>Name:</strong> {member.name}</Typography></Grid>
                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Initiated Name:</strong> {member.initiatedName}</Typography></Grid>
                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Age:</strong> {member.age}</Typography></Grid>
                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Gender:</strong> {member.gender}</Typography></Grid>

                    <Grid item xs={12} sm={4} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Country Code:</strong> {member.countryCode}</Typography></Grid>
                    <Grid item xs={12} sm={4} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Contact Number:</strong> {member.contactNumber}</Typography></Grid>
                    <Grid item xs={12} sm={4} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Email:</strong> {member.email}</Typography></Grid>

                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>City:</strong> {member.city}</Typography></Grid>
                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Preferred Hearing Language:</strong> {member.preferredHearingLanguage}</Typography></Grid>

                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Arrival Date:</strong> {dayjs(member.arrivalTime).format('DD/MM/YYYY')}</Typography></Grid>
                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Departure Date:</strong> {dayjs(member.departureTime).format('DD/MM/YYYY')}</Typography></Grid>

                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Transportation preference:</strong> {member.transportationPreference.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}</Typography></Grid>
                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Transportation charges per day:</strong> {member.transportationEstimate}</Typography></Grid>
                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Prasadam charges per day:</strong> {member.prasadamEstimate}</Typography></Grid>
                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Registration Charges:</strong> {member.registrationCharges}</Typography></Grid>
                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Yatra Charges per day:</strong> {member.yatraCharges}</Typography></Grid>
                    <Grid item xs={12} sm={6} sx={{ border: '1px solid #e0e0e0' }} ><Typography variant="body2"><strong>Late Fees:</strong> {member.lateFees}</Typography></Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};


export default MemberView;
